import * as React from "react";
import { FC } from "react";
import styled from "styled-components";
import tw from "tailwind.macro";
import { Bubble } from "../components/bubble";
import Content from "../elements/content";
import { Divider } from "../elements/dividers";
import { UpDown, UpDownWide, waveAnimation } from "../styles/animations";

const WaveWrapper = styled.div`
  ${tw`absolute pin-b w-full`};
  transform: matrix(1, 0, 0, -1, 0, 0);
`;

const InnerWave = styled.div`
  ${tw`relative h-full`};
  svg {
    width: 100%;
    height: 40vh;
  }
  path {
    ${waveAnimation("20s")};
  }
`;

const Contact: FC = ({ children }) => (
  <>
    <Divider fill="#23262b" speed={0.2} offset={4}>
      <WaveWrapper>
        <InnerWave>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 338.05" preserveAspectRatio="none">
            <path>
              <animate
                attributeName="d"
                values="M 0 100 Q 250 50 400 200 Q 550 350 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 200 150 400 200 Q 600 250 800 300 L 800 0 L 0 0 L 0 100 Z;M 0 100 Q 150 350 400 200 Q 650 50 800 300 L 800 0 L 0 0 L 0 100 Z"
                repeatCount="indefinite"
                dur="30s"
              />
            </path>
          </svg>
        </InnerWave>
      </WaveWrapper>
    </Divider>
    <Content speed={0.4} offset={4}>
      {children}
    </Content>
    <Divider speed={0.1} offset={4}>
      <UpDown>
        <Bubble opacity={0.05} hiddenMobile width={8} left="70%" top="20%" />
        <Bubble opacity={0.05} width={8} left="25%" top="5%" />
      </UpDown>
      <UpDownWide>
        <Bubble opacity={0.05} width={12} left="95%" top="50%" />
        <Bubble opacity={0.05} width={6} left="85%" top="15%" />
      </UpDownWide>
      <Bubble opacity={0.05} width={6} left="4%" top="20%" />
      <Bubble opacity={0.05} width={12} left="70%" top="60%" />
    </Divider>
  </>
);

export default Contact;
