import * as React from "react";
import styled from "styled-components";
import tw from "tailwind.macro";
import { Bubble } from "../components/bubble";
import Content from "../elements/content";
import { Divider } from "../elements/dividers";
import { UpDown, UpDownWide } from "../styles/animations";

const Wrapper = styled.div`
  ${tw`w-full xl:w-2/3`};
`;

const Hero = ({ children }) => (
  <>
    <Divider speed={0.2} offset={0} bg="white" color="#e0e0e0">
      <UpDown>
        <Bubble opacity={0.12} hiddenMobile width={38} left="10%" top="20%" />
        <Bubble opacity={0.1} hiddenMobile width={18} left="14%" top="45%" />
        <Bubble opacity={0.12} width={16} left="60%" top="15%" />
      </UpDown>
      <UpDownWide>
        <Bubble opacity={0.1} hiddenMobile width={16} left="80%" top="10%" />
        <Bubble opacity={0.12} width={12} left="90%" top="50%" />
        <Bubble opacity={0.03} width={16} left="70%" top="90%" />
        <Bubble opacity={0.12} width={16} left="30%" top="65%" />
        <Bubble opacity={0.03} width={6} left="75%" top="10%" />
        <Bubble opacity={0.12} hiddenMobile width={8} left="45%" top="10%" />
      </UpDownWide>
      <Bubble opacity={0.1} hiddenMobile width={24} left="5%" top="70%" />
      <Bubble opacity={0.1} width={6} left="4%" top="20%" />
      <Bubble opacity={0.1} width={12} left="50%" top="60%" />
      <Bubble opacity={0.1} width={8} left="95%" top="90%" />
      <Bubble opacity={0.1} hiddenMobile width={24} left="40%" top="80%" />
      <Bubble opacity={0.1} width={8} left="25%" top="5%" />
      <Bubble opacity={0.1} width={64} left="95%" top="5%" />
      <Bubble opacity={0.2} hiddenMobile width={44} left="5%" top="90%" />
      <Bubble opacity={0.2} width={6} left="10%" top="10%" />
      <Bubble opacity={0.2} width={12} left="40%" top="30%" />
      <Bubble opacity={0.2} width={16} left="10%" top="50%" />
      <Bubble opacity={0.1} width={8} left="80%" top="70%" />
    </Divider>
    <Content speed={0.4} offset={0}>
      <Wrapper>{children}</Wrapper>
    </Content>
  </>
);

export default Hero;
