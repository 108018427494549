import styled from "styled-components";
import tw from "tailwind.macro";

export const ProjectsWrapper = styled.div`
  ${tw`flex flex-wrap justify-between mt-8`};
  display: grid;
  grid-gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 1200px) {
    grid-gap: 3rem;
  }
  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
`;

export const AboutHero = styled.div`
  ${tw`flex flex-col lg:flex-row items-center mt-8`};
`;

export const Avatar = styled.img`
  ${tw`rounded-full w-32 xl:w-48 shadow-lg h-auto`};
`;

export const AboutSub = styled.span`
  ${tw`text-grey-dark pt-12 lg:pt-0 lg:pl-12 text-2xl lg:text-3xl xl:text-4xl`};
`;

export const AboutDesc = styled.p`
  ${tw`text-grey-dark text-lg md:text-xl lg:text-2xl font-sans pt-6 md:pt-12 text-justify`};
`;

export const ContactText = styled.p`
  ${tw`text-grey-light font-sans text-xl md:text-1xl lg:text-2xl text-black`};
  > span {
    line-height: 1.5;
    font-size: 1.5rem;
    padding-left: 10px;
    white-space: nowrap;
    > a {
      padding-left: 5px;
    }
  }
`;

export const Footer = styled.footer`
  ${tw`text-center text-grey absolute pin-b p-6 font-sans text-md lg:text-lg`};
  > span {
    padding-left: 20px;
  }
`;
