import React from "react";
import { Bubble } from "../components/bubble";
import Content from "../elements/content";
import { Divider, DividerMiddle } from "../elements/dividers";
import Inner from "../elements/inner";
import { UpDown, UpDownWide } from "../styles/animations";

const Projects = ({ children }) => (
  <>
    <DividerMiddle
      bg="linear-gradient(to right, SlateBlue 0%, DeepSkyBlue 100%)"
      speed={-0.2}
      offset={1.1}
      factor={2}
    />
    <Content speed={0.4} offset={1.2} factor={2}>
      <Inner>{children}</Inner>
    </Content>
    <Divider speed={0.1} offset={1} factor={2}>
      <UpDown>
        <Bubble width={6} left="85%" top="75%" opacity={1} />
        <Bubble width={8} left="70%" top="720" opacity={1} />
        <Bubble width={8} opacity={0.5} left="25%" top="5%" />
        <Bubble hiddenMobile width={24} opacity={0.6} left="17%" top="60%" />
      </UpDown>
      <UpDownWide>
        <Bubble width={12} opacity={0.8} left="90%" top="30%" />
        <Bubble width={16} opacity={0.8} left="70%" top="90%" />
        <Bubble hiddenMobile width={16} opacity={0.8} left="18%" top="75%" />
        <Bubble width={6} opacity={0.8} left="75%" top="10%" />
      </UpDownWide>
      <Bubble width={6} opacity={0.8} left="4%" top="20%" />
      <Bubble width={12} opacity={0.8} left="80%" top="60%" />
    </Divider>
  </>
);

export default Projects;
