import * as React from "react";
import { FC } from "react";
import styled from "styled-components";
import { width as twWidth } from "../../tailwind";
import { hidden } from "../styles/utils";

interface StyledSvgProps {
  hiddenMobile?: boolean;
  stroke?: string;
  svgWidth?: string;
  viewBox: string;
  className?: string;
  fill?: string;
  left?: string;
  top?: string;
}

const StyledSvg: FC<StyledSvgProps> = styled.svg<StyledSvgProps>`
  position: absolute;
  stroke: currentColor;
  ${(props) => props.hiddenMobile && hidden};
  color: ${(props) => props.stroke};
  width: ${(props) => props.svgWidth};
  fill: ${(props) => props.fill};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
`;

interface BubbleProps {
  width: number;
  left: string;
  top: string;
  opacity: number;
  hiddenMobile?: boolean;
}

export const Bubble: FC<BubbleProps> = ({ width = 8, left = "0%", top = "0%", opacity, hiddenMobile = false }) => (
  <StyledSvg
    viewBox="0 0 30 30"
    stroke="transparent"
    svgWidth={twWidth[`${width}`]}
    left={left}
    top={top}
    hiddenMobile={hiddenMobile}
    fill={`rgba(${64 + opacity * 0},64,${128 + opacity * 500},${opacity / 3})`}
  >
    <path d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,6.23A8.77,8.77,0,1,0,23.77,15,8.77,8.77,0,0,0,15,6.23Z" />
  </StyledSvg>
);
