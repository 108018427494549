import React from "react";
import { Bubble } from "../components/bubble";
import Content from "../elements/content";
import { Divider } from "../elements/dividers";
import Inner from "../elements/inner";
import { UpDown, UpDownWide } from "../styles/animations";

const About = ({ children }) => (
  <>
    <Divider bg="#f0f0f8" clipPath="polygon(0 16%, 100% 4%, 100% 82%, 0 94%)" speed={0.2} offset={3} />
    <Divider speed={0.1} offset={3}>
      <UpDown>
        <Bubble opacity={0.05} hiddenMobile width={6} left="50%" top="75%" />
        <Bubble opacity={0.05} hiddenMobile width={8} left="70%" top="20%" />
        <Bubble opacity={0.05} hiddenMobile width={24} left="80%" top="80%" />
      </UpDown>
      <UpDownWide>
        <Bubble opacity={0.05} hiddenMobile width={16} left="5%" top="80%" />
        <Bubble opacity={0.05} width={12} left="95%" top="50%" />
        <Bubble opacity={0.05} hiddenMobile width={8} left="45%" top="10%" />
      </UpDownWide>
      <Bubble opacity={0.05} width={6} left="4%" top="20%" />
      <Bubble opacity={0.05} width={12} left="20%" top="30%" />
      <Bubble opacity={0.05} width={8} left="80%" top="70%" />
    </Divider>
    <Content speed={0.4} offset={3}>
      <Inner>{children}</Inner>
    </Content>
  </>
);

export default About;
