import styled from "styled-components";
import tw from "tailwind.macro";
import { rotateAnimation } from "../styles/animations";

export const Title = styled.h1`
  ${tw`text-4xl lg:text-4xl font-serif mb-4 tracking-wide relative`};
  text-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  color: #222;
  margin-top: 44px;
  padding-top: 5px;
  border-top: 1px dashed #000;
  &:before {
    width: 40px;
    height: 40px;
    position: absolute;
    background-size: 40px;
    /* ${rotateAnimation("4s")}; */
    left: -60px;
    top: 5px;
  }
`;

export const BigTitle = styled.h1`
  ${tw`text-5xl lg:text-6xl font-serif text-black mb-6 tracking-wide`};
  text-shadow: 0 15px 35px rgba(0, 0, 0, 0.65);
`;

export const Subtitle = styled.p`
  ${tw`text-2xl lg:text-4xl font-sans text-grep-dark mt-8 xxl:w-3/4`};
  text-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
`;
