import React, { FC } from "react";
import styled from "styled-components";
import {  Link } from "gatsby";
import tw from "tailwind.macro";

const Wrapper = styled(Link)<{ bg? : string }>`
  width: 100%;
  color: white;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  /*rounded-lg */
  border-radius: 0.5rem;
  /* relative */
  position: relative;
  /* shadow-lg */
  box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);

  background: ${props => props.bg};
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  &:hover {
    transform: translateY(-5px) scale(1.04);
  }
  @media (min-width: 600px) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
`;

const Text = styled.div`
  ${tw`opacity-75 font-sans text-sm md:text-base`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`;

const Title = styled.div`
  ${tw`text-white uppercase text-2xl md:text-3xl xl:text-4xl tracking-wide font-sans pt-8`};
  text-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
`;

interface ProjectCardProps {
  title:string;
  link:string;
  bg:string;
}

const ProjectCard : FC<ProjectCardProps>= ({ title, link, children, bg }) => (
  <Wrapper to={link}  bg={bg}>
    <Text>{children}</Text>
    <Title>{title}</Title>
  </Wrapper>
);

export default ProjectCard;
