import { graphql } from "gatsby";
import React from "react";
import { FaEnvelope, FaGithub, FaLinkedin } from "react-icons/fa";
import { Parallax } from "react-spring/renderprops-addons";
// Components
import Layout from "../components/layout";
import ProjectCard from "../components/project-card";
import { AboutDesc, AboutHero, AboutSub, Avatar, ContactText, Footer, ProjectsWrapper } from "../styles/style-comp";
// Elements
import Inner from "../elements/inner";
import { BigTitle, Subtitle, Title } from "../elements/titles";
import * as avatar from "../images/avatar.jpg";
import About from "../views/about";
import Contact from "../views/contact";
// Views
import Hero from "../views/hero";
import Projects from "../views/projects";

const Index = ({
  data: {
    allMarkdownRemark: { nodes: markdowns },
    allFountain: { nodes: fountains },
  },
}) => {
  return (
    <>
      <Layout />
      <Parallax pages={5}>
        <Hero>
          <BigTitle>
            Sophia Wood, <br /> Portfolio
          </BigTitle>
          <Subtitle>Copywriting, UX Writing, Editing.</Subtitle>
        </Hero>
        <Projects>
          <Title>Marketing and Social Media</Title>
          <ProjectsWrapper>
            {markdowns
              .filter((m) => m.frontmatter?.category === "marketing")
              .map((n) => (
                <ProjectCard
                  title={n.frontmatter.title}
                  link={n.fileAbsolutePath.slice(n.fileAbsolutePath.lastIndexOf("/")) + "/"}
                  bg="linear-gradient(to bottom, #afa7f0 0%, #aBc0fB 100%)"
                >
                  {n.frontmatter.description}
                </ProjectCard>
              ))}
          </ProjectsWrapper>
          {/* <!-- Writing Projects --> */}
          <Title>Content Writing</Title>
          <ProjectsWrapper>
            <ProjectCard
              bg="linear-gradient(to bottom, #bfb790 0%, #fBf08B 100%)"
              title="Neowin"
              link="https://www.neowin.net/news/poster/sophia_wood/"
            >
              Video Game Journalism
            </ProjectCard>
          </ProjectsWrapper>
          <Title>Writing Projects</Title>
          <ProjectsWrapper>
            {markdowns
              .filter((m) => m.frontmatter?.category === "writing")
              .map((n) => (
                <ProjectCard
                  title={n.frontmatter.title}
                  link={n.fileAbsolutePath.slice(n.fileAbsolutePath.lastIndexOf("/")) + "/"}
                  bg="linear-gradient(to bottom right, #54a45A 0%, #8BB0eB 100%)"
                >
                  {n.frontmatter.description}
                </ProjectCard>
              ))}
            {fountains.map((f) => (
              <ProjectCard
                title={f.title}
                link={f.fileAbsolutePath.slice(f.fileAbsolutePath.lastIndexOf("/")).toLowerCase() + "/"}
                bg="linear-gradient(to bottom right, #54a45A 0%, #8BB0eB 100%)"
              >
                {f.source}
              </ProjectCard>
            ))}
          </ProjectsWrapper>
        </Projects>
        <About>
          <Title>About</Title>
          <AboutHero>
            <Avatar src={avatar} alt="Sophia Wood" />
            <AboutSub>Passionate about the user-experience and telling stories.</AboutSub>
          </AboutHero>
          <AboutDesc>
            I am a recent graduate of Bath Spa University. After majoring in Creative Writing, I wrote for Neowin.net
            covering video game news. I am the co-host and editor of "The Pixels Podcast" and "Is It Too Late to Drop
            Out?" This past year I have focused on learning web development and now know HTML5, CSS, JavaScript, and
            React. Specialties include technical articles and UX Writing.
          </AboutDesc>
        </About>
        <Contact>
          <Inner>
            <Title>Get in touch</Title>
            <ContactText>
              <span>
                <FaEnvelope />

                <a href="mailto:sophiaew@live.com">sophiaew@live.com</a>
              </span>
              <span>
                <FaLinkedin />

                <a href="https://www.linkedin.com/in/sophia-wood-729459180/">linkedin/sophia-wood</a>
              </span>
              <span>
                <FaGithub />
                <a href="https://www.github.com/sophiawood">github/sophiawood</a>
              </span>
            </ContactText>
          </Inner>
          <Footer>
            &copy; 2021 by Sophia Wood, inspired by Gatsby Portfolio Starter Cara
            <span>
              <a href="https://github.com/sophiawood">Github Repository</a>
            </span>
          </Footer>
        </Contact>
      </Parallax>
    </>
  );
};

export const pageQuery = graphql`
  query {
    allFountain {
      nodes {
        title
        fileAbsolutePath
        draft_date
        date
        source
      }
    }
    allMarkdownRemark {
      nodes {
        fileAbsolutePath
        id
        frontmatter {
          title
          description
          category
        }
        html
        timeToRead
      }
    }
  }
`;

export default Index;
