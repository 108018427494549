import styled from 'styled-components'
import tw from 'tailwind.macro'
import { ParallaxLayer } from 'react-spring/renderprops-addons'

const Content = styled(ParallaxLayer)<{bg?:string}>`
  ${tw`p-6 md:p-12 lg:p-24 justify-center items-center flex z-50`};
  background-color: ${props => props.bg}
`

export default Content
