import styled from 'styled-components'
import * as React from "react"
import tw from 'tailwind.macro'
import { ParallaxLayer, ParallaxLayerProps } from 'react-spring/renderprops-addons'
import { FC } from 'react'

interface DividerProps  extends ParallaxLayerProps {
  color?:string;
  fill?:string;
  clipPath?:string;
  bg?:string;
}



export const Divider = styled(ParallaxLayer)<DividerProps>`
  ${tw`absolute w-full h-full`};
  background: ${props => props.bg || "transparent"};
  color: ${props => props.color || undefined};
  svg {
    fill: ${props => props.fill};
  }
  clip-path: ${props => props.clipPath};
`

export const DividerMiddle = styled(Divider)`
  clip-path: polygon(0 15%, 100% 25%, 100% 85%, 0 75%);
`

